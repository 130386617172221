<template>
  <van-dialog
    v-model="show"
    class="dialog"
    show-cancel-button
    @close="confirm"
    @confirm="confirm"
  >
    <van-form style="position: relative; margin-top: 2rem">
      <van-cell-group inset>
        <van-sticky>
          <van-field v-model="form.code" name="code" label="采购单号" />
          <van-cell @click="showStockSelect">
            <van-field v-model="form.scName" name="scName" label="仓库"
          /></van-cell>
          <van-cell @click="showSupplierSelect">
            <van-field
              v-model="form.supplierName"
              name="supplierName"
              label="供应商"
          /></van-cell>
          <van-cell>
            <van-button :loading="loading" type="primary" @click="search"
              >查询</van-button
            >
          </van-cell>
        </van-sticky>
      </van-cell-group>
      <div class="tableBox">
        <CustomTable
          :scrollWidth="600"
          :columns="columns"
          :tableData="tableData"
          @selectedRowChange="showActive"
          :total="totalCount"
          :pageIndex="pageIndex"
          :pageSize="pageSize"
          @pageChange="pageChange"
        ></CustomTable>
      </div>
      <StockSelect
        :visible="stockSelectVisible"
        @confirm="stockSelectConfirm"
      ></StockSelect>
      <SupplierSelect
        :visible="supplierSelectVisible"
        @confirm="supplierSelectConfirm"
      ></SupplierSelect>
    </van-form>
    <!-- <van-cell style="position: absolute;bottom:0;">
      <van-button round block type="primary" @click="confirm">
        提交
      </van-button>
    </van-cell> -->
  </van-dialog>
</template>
<script>
import { AVAILABLE } from "../utils/constants";
import { queryWithReceive } from "../service/api/purchase/order";
import StockSelect from "./stockSelect.vue";
import SupplierSelect from "./supplierSelect..vue";
import CustomTable from "./customTable.vue";
// 导入样式
export default {
  name: "purchaseOrderSelect",
  components: {
    StockSelect,
    SupplierSelect,
    CustomTable,
  },
  props: {
    visible: false,
  },
  data() {
    return {
      supplierSelectVisible: false,
      stockSelectVisible: false,
      loading: true,
      show: false,
      data: [],
      form: { code: "", name: "" },
      pageSize: 20,
      pageIndex: 1,
      totalCount: 0,
      totalPage: 1,
      columns: [
        {
          field: "name",
          key: "b",
          // type=radio
          type: "radio",
          title: "单选",
          width: 30,
          align: "left",
        },
        { field: "code", key: "code", title: "采购单号" },
        { field: "scCode", key: "scCode", title: "仓库编号" },
        { field: "scName", key: "scName", title: "仓库名称" },
        { field: "supplierCode", key: "supplierCode", title: "供应商编号" },
        { field: "supplierName", key: "supplierName", title: "供应商名称" },
        { field: "createTime", key: "createTime", title: "操作时间" },
        { field: "createBy", key: "createBy", title: "操作人" },
      ],
      tableData: [],
      statusList: AVAILABLE,
      categoryList: [],
      currentRow: {},
    };
  },
  watch: {
    visible(newVal, oldVal) {
      this.show = newVal;
    },
  },
  created() {
    this.search();
  },
  methods: {
    showStockSelect() {
      this.stockSelectVisible = true;
    },
    stockSelectConfirm(row) {
      if (Object.keys(row).length === 0) {
        this.stockSelectVisible = false;
        return;
      }
      this.stockSelectVisible = false;
      this.form.scName = row.name;
      this.form.scId = row.id;
    },
    showSupplierSelect() {
      this.supplierSelectVisible = true;
    },
    supplierSelectConfirm(row) {
      if (Object.keys(row).length === 0) {
        this.supplierSelectVisible = false;
        return;
      }
      this.supplierSelectVisible = false;
      this.form.supplierName = row.name;
      this.form.supplierId = row.id;
    },
    search() {
      this.getProductList();
      // this.categoryList = this.data.filter((el) => {
      //   console.log(this.form.code,el.code.includes(this.form.code),this.form.name,el.name.includes(this.form.name))
      //   return el.code.includes(this.form.code)&&el.name.includes(this.form.name)
      // });
    },
    showActive(item) {
      this.currentRow = item;
    },
    confirm() {
      this.$emit("confirm", this.currentRow);
      this.show = false;
      this.currentRow = {};
    },
    pageChange(pageOptions) {
      this.pageIndex = pageOptions.pageIndex;
      this.pageSize = pageOptions.pageSize;
      this.getProductList();
    },
    getProductList() {
      const params = Object.assign(this.form, {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      });
      console.log(params);
      queryWithReceive(params).then((res) => {
        console.log(res, "采购单id");
        this.tableData = res.data.datas;
        this.loading = false;
        this.totalCount = res.data.totalCount;
        this.totalPage = res.data.totalPage;
        this.pageSize = res.data.pageSize;
        this.pageIndex = res.data.pageIndex;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.active {
  background: #1989fa;
}
.dialog {
  height: 80%;
}
// .van-cell_title {
//   color: #333;
//   font-size: 12px;
// }
::v-deep .van-dialog__content {
  height: 100%;

  .van-form {
    height: 100%;
  }
}
.tableBox {
  height: calc(100% - 350px);
  overflow-y: auto;
}
.scrollList {
  height: calc(100% - 185px);
  overflow: auto;
}
::v-deep .van-dialog__footer {
  position: sticky;
  bottom: 0;
}
</style>
